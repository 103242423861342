// Import libraries
import React from 'react';


// Import navigation context
import { MenuProvider } from './src/context/navigation';


// wrapRootElement
// Source: https://www.gatsbyjs.org/docs/browser-apis/#wrapRootElement
export const wrapRootElement = ({ element }) => {
    return (
        <MenuProvider>{element}</MenuProvider>
    );
};