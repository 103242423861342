// Import libraries
import React, { useState, useEffect, createContext } from 'react';


// Create navigation context
const NavigationContext = createContext({
    navigationIsOpen: false,
    viewportSize: {
        width: 0,
        height: 0
    }
});


// Create component
const MenuProvider = ({ children }) => {
    const [navigationIsOpen, setNavigationIsOpen] = useState(false);
    const [viewportSize, setViewportSize] = useState({ width: 0, height: 0 });


    // Runs after components have mounted
    useEffect(() => {
        // Resize event listener on window
        window.addEventListener('resize', () => setViewportSize({
            width: window.innerWidth < window.outerWidth ? window.innerWidth : window.outerWidth,
            height: window.innerHeight < window.outerHeight ? window.innerHeight : window.outerHeight
        }), false);

        // Calculate viewport size after components have rendered
        setViewportSize({
            width: window.innerWidth < window.outerWidth ? window.innerWidth : window.outerWidth,
            height: window.innerHeight < window.outerHeight ? window.innerHeight : window.outerHeight
        });
    }, []);


    return (
        <NavigationContext.Provider
            value={{
                navigationIsOpen,
                setNavigationIsOpen,
                viewportSize
            }}
        >
            {children}
        </NavigationContext.Provider>
    );
};


// Exports
export {
    NavigationContext as default,
    MenuProvider
};